<template>
    <div class="section-list" v-if="!isSlider" :class="`section-list--${view}`">
        <localized-link 
            :to="{name: 'Article', params: { name: item.uri }}" 
            class="section-item" 
            :class="{ 'section-item--search' : isSearchList }"
            v-for="item in items" 
            :key="item.id"
        >
            <div class="section-item__image" v-if="item.image" :style="{ backgroundImage: 'url(' + storage(item.image) + ')' }"></div>
            <div class="section-item__image" v-else></div>

            <div class="section-item__list">
                <div class="section-item__header">
                    <div class="section-item__headerItem">
                        <span class="material-icons-round section-item__icon">access_alarm</span>
                        <p class="section-item__p" style="text-transform: capitalize;">{{ item.date | getDate }}</p>
                    </div>
                    <div class="section-item__headerItem" v-if="item.author">
                        <span class="material-icons-round section-item__icon">person_outline</span>
                        <p class="section-item__p">{{ item.author }}</p>
                    </div>
                </div>

                <h4 class="typography typography--h4 section-item__title">{{ item.title }}</h4>
                <p class="typography typography--paragraph section-item__subtitle">{{ item.description }}</p>
            </div>

            <button type="button" class="section-item__more">
                <span class="material-icons-round section-item__icon--white">arrow_forward_ios</span>
            </button>
        </localized-link>
    </div>

    <div v-else class="section-list section-list--slider" :class="`section-list--${view}`">
        <VueSlickCarousel
            v-bind="sliderSettings"
            class="section-list__slider"
        >
            <localized-link :to="{name: 'Article', params: { name: item.uri }}" class="section-item" v-for="item in items" :key="item.id">
                <div class="section-item__image" v-if="item.image" :style="{ backgroundImage: 'url(' + storage(item.image) + ')' }"></div>
                <div class="section-item__image" v-else></div>

                <div class="section-item__list">
                    <div class="section-item__header">
                        <div class="section-item__headerItem">
                            <span class="material-icons-round section-item__icon">access_alarm</span>
                            <p class="section-item__p" style="text-transform: capitalize;">{{ item.date | getDate }}</p>
                        </div>
                        <div class="section-item__headerItem">
                            <span class="material-icons-round section-item__icon">person_outline</span>
                            <p class="section-item__p">{{ item.author }}</p>
                        </div>
                    </div>

                    <h4 class="typography typography--h4 section-item__title">{{ item.title }}</h4>
                    <p class="typography typography--paragraph section-item__subtitle">{{ item.description }}</p>
                </div>

                <button type="button" class="section-item__more">
                    <span class="material-icons-round section-item__icon--white">arrow_forward_ios</span>
                </button>
            </localized-link>

             <template #prevArrow>
                <span class="material-icons">keyboard_arrow_up</span>
            </template>

            <template #nextArrow>
                <span class="material-icons">keyboard_arrow_down</span>
            </template>

        </VueSlickCarousel>
    </div>
</template>

<script>

export default {
    props: {
        items: {
            type: Array,
            required: true
        },

        view: {
            required: false,
            type: String,
            default: 'grid'
        },

        isSlider: {
            required: false,
            type: Boolean,
            default: false
        },

        isSearchList: {
            required: false,
            type: Boolean,
            default: false
        },
        /* если false, не показыаваем 4й элемент на max-width: $xxl */
    },

    data() {
        return {
            sliderSettings: {
                infinite: true,
                dots: true,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }
    }
}
</script>

<style lang="scss" scoped>

    @import "@/assets/scss/article-content/index.scss";

    $r: '.section-list';

    .section-list:not(.section-list--slider) {
        display: flex;
        margin: 20px -60px 0;

        &#{$r}--grid {
            flex-wrap: wrap;
        }

        &#{$r}--list {
            flex-direction: column;
        }

        @media screen and (max-width: $lg) {
            margin: 20px -30px 0;
        }
        
    }

    .section-item {
        display: flex;
        flex-basis: calc(25% - 120px);

        #{$r}--grid & {
            flex-direction: column;
            margin: 30px 60px;

            @media screen and (max-width: $lg) {
                margin: 30px;
            }
        }

        #{$r}--slider & {
            margin: 40px 0 30px;
        }

        #{$r}--list & {
            margin: 15px 60px;

            @media screen and (max-width: $lg) {
                margin: 15px 30px;
            }
        }

        @media screen and (max-width: $xxl) {
            flex-basis: calc(33.33% - 120px);
        }

        @media screen and (max-width: $xl) {
            flex-basis: calc(50% - 120px);
        }

        @media screen and (max-width: $lg) {
            flex-basis: calc(50% - 60px);
        }

        @media screen and (max-width: $sm) {
            flex-basis: calc(100% - 60px)
        }

        &:nth-child(4):not(.section-item--search) {
            @media screen and (max-width: $xxl) {
                display: none;
            }

            @media screen and (max-width: $xl) {
                display: flex;
            }
        }

        &__list {
            #{$r}--list & {
                margin: 0 20px;
                width: calc(100% - 460px);
            }
        }

        &__image {
            background-color: $grey;
            width: 100%;
            height: 190px;
            background-position: center;
            background-size: cover;

            #{$r}--grid & {
                width: 100%;
                margin-bottom: 18px;
            }

            #{$r}--list & {
                flex-direction: column;
                width: 380px;
            }

            #{$r}--slider & {
                height: 300px;
            }

            @media screen and (max-width: $sm) {
                height: 220px;
            }
        }

        &__icon {
            margin-right: 8px;
            color: $blue;
            font-size: 18px;

            &--white {
                color: white;
                font-size: 14px;
                margin: 7px;
            }
        }

        &__header {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        &__headerItem {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 20px;
            }
        }

        &__p {
            font-size: 13px;
            margin: 0;
            color: $dark !important;
        }

        &__title {
            font-size: 24px;
            margin-bottom: 10px;
            word-break: break-word;
        }

        &__subtitle {
            font-size: 18px;
            color: #4e515f;
            padding-bottom: 20px;
            word-break: break-word;
        }

        &__more {
            border-radius: 50%;
            background-color: $blue;
            padding: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;

            #{$r}--grid & {
                margin: auto auto 0;
            }

            #{$r}--list & {
                margin: auto 0;
            }
        }
    }
</style>

<style lang="scss">

    /* rewrite base library style */

    .slick-arrow.slick-prev, .slick-arrow.slick-next {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .03s;
        font-size: 28px;
        background-color: $blue;
        border-radius: 50%;
        padding: 10px;

        &:before {
            display: none;
        }
    }

    .section-list__slider {
        .slick-slide {
            > div {
                margin: 0 4px;
            }
        }

        .slick-arrow.slick-prev {
            left: -50px;
            top: 49%;
            transform: translate(0%, -50%) rotate(-90deg);
        }

        .slick-arrow.slick-next {
            right: -50px;
            left: auto;
            top: 49%;
            transform: translate(0%, -50%) rotate(-90deg);
        }
    }

    .slick-dots li {
        margin: 0;

        button {
            &:before {
                font-size: 11px;
                color: $blue
            }
        }

        &.slick-active {
            button {
                &:before {
                    color: $blue
                }
            }
        }
    }
</style>